

















import { ValidationObserver } from 'vee-validate/dist/vee-validate.full.esm'
import { Component, Vue, Prop, Watch, Ref } from 'vue-property-decorator'
import { componentsLookupTable, componentsImports } from './propertyFormsLookup'
import { Services } from 'truemarket-modules/src/services'

@Component({
  components: { ValidationObserver, ...componentsImports() }
})
export default class PropertyDetails extends Vue {
  @Ref('observer') readonly form!: InstanceType<typeof ValidationObserver>
  // eslint-disable-next-line
  @Ref('currentForm') readonly currentForm!: any

  created () {
    this.$emit('submissionHandler', this.submit)
    this.$emit('validationHandler', this.validate)
  }

  componentMounted () {
    const tempValId = this.$route.query.tempValuationId as string
    const pristine = this.form.flags.pristine

    if (tempValId && pristine) this.loadData(tempValId)
  }

  loadData (tempValId: string) {
    Services.API.ValuationForm.ResumeTempValuation(
      { TempValuationId: tempValId, Key: 'PropertyDetails' }
    ).then((response: any) => {
      if (!response.Data) return

      this.currentForm.loadData(response.Data)
    })
  }

  validate () {
    return this.form.validateWithInfo()
  }

  @Prop({ required: true })
  public tempValuationId!: string;

  async submit () {
    const formData = this.currentForm?.formData ? this.currentForm.formData() : {}
    const request = {
      TempValuationId: this.tempValuationId,
      Version: Vue.prototype.$valuationFromVersion,
      PropertyType: this.currentForm?.propertyType(),
      Key: 'PropertyDetails',
      Data: JSON.stringify(formData)
    }
    return await Services.API.ValuationForm.ProcessTempValuation(request).then(response => {
      return {
        data: formData,
        response,
        success: true
      }
    }).catch((e) => {
      return {
        data: formData,
        response: e,
        success: false
      }
    })
  }

  @Prop({ required: true })
  public propertyType!: string;

  @Prop({ required: true })
  public propertyClassification!: string;

  @Watch('propertyType')
  @Watch('propertyClassification')
  propsChanged () {
    this.componentToLoad = this.lookupComponent(this.propertyType, this.propertyClassification)
  }

  lookupComponent (type: string, classification: string) {
    console.log('issue with tables check here //////////////////////////' + type)
    console.log('issue with tables check here //////////////////////////' + classification)
    console.log('issue with tables check here //////////////////////////')
    if (!componentsLookupTable[type]) {
      return null
    }

    return componentsLookupTable[type][classification]
  }

  componentToLoad: string | null = null
}
