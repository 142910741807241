































/* eslint-disable @typescript-eslint/camelcase */

import { Component, Vue, Prop, Watch, Ref } from 'vue-property-decorator'
import { Services, ServiceManager, ServiceType } from 'truemarket-modules/src/services'
import StripeService from 'truemarket-modules/src/services/payment/stripe/StripeService'
import { loadStripe, Stripe, StripeElements } from '@stripe/stripe-js'
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full.esm'
import InputText from '@/components/Controls/InputText.vue'
import Checkbox from '@/components/Controls/Checkbox.vue'
import ConfirmDialog from 'primevue/confirmdialog'
import Button from 'primevue/button'
import router from '@/router'

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    ConfirmDialog,
    InputText,
    Checkbox,
    Button
  }
})
export default class Payment extends Vue {
  @Ref('voucherObserver') readonly voucherForm!: InstanceType<typeof ValidationObserver>

  created () {
    this.$emit('submissionHandler', this.submit)
    this.$emit('validationHandler', this.validate)
  }

  async mounted () {
    this.returnUrl = this.stripeService.GetReturnUrl()
    this.stripe = await loadStripe(this.stripeService.GetPublishableKey())

    const tempValId = this.$route.query.tempValuationId as string
    const pristine = this.voucherForm.flags.pristine

    if (tempValId && pristine) this.loadData(tempValId)
  }

  loadData (tempValId: string) {
    Services.API.ValuationForm.ResumeTempValuation(
      { TempValuationId: tempValId, Key: 'Voucher' }
    ).then((response: any) => {
      if (!response.Data) return

      this.voucher = response.Data.Voucher
    })
  }

  @Prop({ required: true })
  public tempValuationId!: string;

  @Prop({ required: true })
  public propertyClassification!: string;

  @Prop({ required: true })
  public purpose!: string;

  @Watch('propertyClassification')
  @Watch('purpose')
  propsOnChange () {
    this.setupPaymentIntent()
  }

  agreeUseVoucher () {
    this.$confirm.require({
      message: `You have entered the TrueMarket voucher/coupon ${this.voucher} by pressing confirm
      you declare you have permission to use this voucher/coupon code.`,
      header: 'Voucher / coupon confirmation',
      accept: this.handleSubmitVoucher,
      acceptLabel: 'Confirm',
      rejectLabel: 'Cancel'
    })
  }

  setupPaymentIntent () {
    if (!this.propertyClassification || !this.purpose || !this.tempValuationId) return

    return Services.API.Payment.CreateTempValuationPaymentIntent(this.tempValuationId)
      .then(
        (response) => {
          this.clientSecret = response.ClientSecret
          this.amount = response.Amount
          this.discount = response.Discount

          if (response.Amount === 0) {
            const paymentNode = document.getElementById('payment-element')
            if (paymentNode) paymentNode.innerHTML = ''
            return
          }

          if (!this.stripe) return

          this.elements = this.stripe.elements({
            clientSecret: this.clientSecret
          })

          const paymentElement = this.elements.create('payment', {
            layout: 'accordion'
          })
          paymentElement.mount('#payment-element')
        }
      )
  }

  validate () {
    this.termsAndConditionsInvalid = !this.termsAndConditions

    return {
      isValid: this.termsAndConditions
    }
  }

  async submit () {
    if (this.termsAndConditionsInvalid) return

    if (this.amount === 0) {
      return await this.submitViaTrueMarket()
    } else {
      return await this.submitViaStripe()
    }
  }

  async submitViaTrueMarket () {
    const request = {
      TempValuationId: this.tempValuationId,
      Version: Vue.prototype.$valuationFromVersion,
      Key: 'Complete',
      Data: JSON.stringify('')
    }

    return await Services.API.ValuationForm.CompleteTempValuation(request).then(response => {
      router.push('/dashboard/admin/temp_valuations')

      return {
        response,
        success: true
      }
    }).catch((e) => {
      return {
        response: e,
        success: false
      }
    })
  }

  async submitViaStripe () {
    if (!this.stripe || !this.clientSecret || !this.elements) return

    await this.elements.submit()

    const { error } = await this.stripe.confirmPayment({
      elements: this.elements,
      clientSecret: this.clientSecret,
      confirmParams: {
        return_url: this.returnUrl
      }
    })

    return {
      success: error === null
    }
  }

  async handleSubmitVoucher () {
    const validationResult = await this.voucherForm.validateWithInfo()

    if (
      (validationResult && !validationResult.isValid) ||
      this.voucher === '') return

    const data = {
      Voucher: this.voucher
    }
    const request = {
      TempValuationId: this.tempValuationId,
      Version: Vue.prototype.$valuationFromVersion,
      Key: 'Voucher',
      Data: JSON.stringify(data)
    }

    await Services.API.ValuationForm.ProcessTempValuation(request)
    await this.setupPaymentIntent()
  }

  termsAndConditionsInvalid = false
  termsAndConditionsError = 'You must agree to the terms and conditions'
  termsAndConditions = false
  termsAndConditionsLabel = 'I agree to the <a href="https://truemarket.com.au/terms-and-conditions" target="_blank">Terms &amp; Conditions</a>'

  discount: number | null = null
  amount: number | null = null
  private clientSecret: string | null = null
  private stripeService = ServiceManager.Require<StripeService>(ServiceType.Stripe)
  private stripe: Stripe | null = null
  private elements: StripeElements | undefined
  private returnUrl = ''

  voucher = ''
}
